<template>
  <h2>Fetch Service</h2>
  <p class="styleguide-p-sourcecode">
    <em>URL API cible: {{ api }}</em
    ><br />
    <em>Source code: <code>services/fetch.service.js</code></em>
  </p>

  <h3 class="styleguide-h3">GET</h3>
  <p class="styleguide-p-sourcecode">
    <code>
      this.fetchService.post("utilisateur").then(<br />
      (response) => {<br />
      console.log(response);<br />
      },<br />
      (error) => {<br />
      console.log(error);<br />
      }<br />
      );
    </code>
  </p>
  <pre><code>{{ JSON.stringify(users, null, 2) }}</code></pre>
  <h3 class="styleguide-h3">POST</h3>
  <p><code>services/fetch.service.js</code></p>
</template>

<script>
export default {
  name: 'StyleguideFetcherView',

  data() {
    return {
      users: {},
      api: '',
    }
  },
  mounted() {
    this.getUser()
  },
  methods: {
    getUser() {
      const params = {
        sort: 'nom.DESC',
        limit: '1',
      }

      this.fetchService.get('utilisateur', params).then(
        (response) => {
          this.users = response
        },
        (error) => {
          console.log(error)
        },
      )
    },
  },
}
</script>
